import GLOBAL from '@/utils/global'

function getDictNameForCode(code) {
  if(code !=0 && !code) {
    return
  }

  let name = ''
  GLOBAL.dictList.some(item=>{
    if(item.value == code) {
      name = item.label
      return true
    }
  })
  return name
}

function getDictList(key) {
  if(!key) {
    return
  }
  let temp = GLOBAL.dictList.filter(item=>item.typeCode===key)
  return temp
}

function getWarningStatusColor(code) {
  let str = ''
  switch (Number(code)) {
    case 1011006:
    case 1011300:
    case 1011100:
    case 1011009:
    case 1011002:
    case 101101201:
    case 1011018:
    case 1011102:
    case 1011101:
    case 1011009:
    case 1011002:
    case 1011017:
      str = 'g-red'
      break
    case 1011013:
    case 1011010:
      str = 'g-orange'
      break
    default:
      str = 'g-green'
      break
  }
  return str
}
function getWarningStatusHTML(keyStr) {
  if(!keyStr) {
    return
  }
  let temp = keyStr.split('#')
  let str = ''
  temp.forEach((item,index)=>{
    if (index != 0) {
      str += ','
    }
    str += `<span class="${getWarningStatusColor(item)}">${getDictNameForCode('warning_status',item)}</span>`
  })
  return str
}

function getTree(data, root, fatherId, childId) {
  let cloneData = JSON.parse(JSON.stringify(data)); // 对源数据深度克隆
  let tree = cloneData.filter(father => {
    //循环所有项
    let branchArr = cloneData.filter(child => {
      return father[childId] == child[fatherId]; //返回每一项的子级数组
    });
    if (branchArr.length > 0) {
      father.children = branchArr; //如果存在子级，则给父级添加一个children属性，并赋值
    }
    return father[fatherId] == root; //返回第一层
  });
  return tree; //返回树形数据
}

export default {
  getDictNameForCode,
  getDictList,
  getTree,
  getWarningStatusHTML,
  getWarningStatusColor
}