// export const URL_PREV = process.env.NODE_ENV==='release'?'http://10.130.131.242:18088/hsa-med-check/':'/api/qinghai/'

export const URL_PREV = '/api/'


// export const URL_PREV = 'http://10.130.131.242:18088/hsa-med-check/'
// export const URL_PREV = '/api/qinghai/'



let dictList = [
  {label: '已激活',value: '1011000'},
  {label: '码错误',value: '1011100'},
  {label: '未激活',value: '1011101'},
  {label: '已注销',value: '1011102'},
  {label: '重复鉴证', value: '1011300'}
]
const CHECK_CODE_ZC = '1011000' // 鉴核结果编码-正常
const CHECK_CODE_JYCW = '1011100' // 鉴核结果编码-校验错误
const CHECK_CODE_WJH = '1011101' // 鉴核结果编码-未激活
const CHECK_CODE_YZX = '1011102' // 鉴核结果编码-已注销
const CHECK_CODE_CFJZ = '1011300' // 鉴核结果编码-重复鉴证
const PAGE_SIZES = [10, 15, 20]
const PAGE_LAYOUT = 'total, prev, pager, next, sizes, jumper'
const PAGER_COUNT = 6

// 图表公共配置
const ZHU_CHARTS = {
  tooltip: {
    trigger: "axis",
    textStyle: {
      fontSize: 12,
      color: "#606266",
    },
    formatter: (params, ticket, callback) => {
      let str = params[0].axisValueLabel + "<br/>";
      params.forEach((item, index) => {
        str += `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${item.color};margin-right:10px"></span><span style="display:inline-block;width:80px">${item.seriesName}</span><span style="font-weight:bold;display:inline-block;min-width:80px;text-align:right;padding-left:10px">${item.value}</span>`;
        if (item.seriesName.substring(item.seriesName.length - 1) == '率') {
          str += "%";
        } else {
          str += "<br/>";
        }
      });
      return str;
    },
  },
  legend: {
    bottom: 5,
    icon: "roundRect",
    textStyle: {
      fontSize: 12,
      color: "#909399",
    }
  },
  grid: {
    top: "40",
    left: "10",
    right: "10",
    bottom: "30",
    containLabel: true
  },
  xAxis: {
    type: "category",
    axisPointer: {
      type: "shadow",
    },
    axisLabel: {
      interval: 0,
      margin: 5,
      rotate: 45,
      color: "#606266",
      fontSize: 12,
    },
    axisLine: {
      lineStyle: {
        color: ["#cecece"],
      },
    },
  },
  yAxis: {
    type: "value",
    name: "数量",
    nameTextStyle: {
      color: "#606266",
      fontSize: 12,
      align: "left",
    },
    axisLabel: {
      // formatter: "{value}",
      formatter: (value, index) => {
        if (typeof value === 'string') {
          var strs = value.split('');
          var str = ''
          for (var i = 0, s; s = strs[i++];) {
            str += s;
            if (!(i % 10) && strs.length > (i + 1)) str += '\n';
          }
          return str
        } else {
          return value
        }

      },
      color: "#606266",
      fontSize: 12,
      // width:150,
      // overflow: 'breakAll',
      lineHeight: 16
    },
    splitLine: {
      lineStyle: {
        color: ["#cecece"],
        type: "dashed",
        width: 1,
      },
    },
  },
  yAxis2: {
    type: "value",
    name: "核查率",
    nameTextStyle: {
      color: "#606266",
      fontSize: 12,
      align: "left",
    },
    splitLine: {
      show: false,
    },
    min: 0,
    max: 100,
    axisLabel: {
      formatter: "{value} %",
      color: "#606266",
      fontSize: 12,
    }
  },
  series: {
    type: "bar",
    barMinWidth: 8,
    barMaxWidth: 16,
    itemStyle: {
      normal: {
        color: "#1b65b9",
      },
    }
  }
}

const YIBIAO_CHARTS = {
  title: {
    top: 10,
    left: 10,
    textStyle: {
      color: "#303133",
      fontSize: 16,
    },
  },
  tooltip: {
    formatter: "{a} {b} :<br/> {c}%"
  },
  series: {
    type: "gauge",
    detail: {
      formatter: "{value}%"
    },
    title: {
      offsetCenter: ["2%", "100%"],
      fontSize: 12,
      color: "#909399",
    },
    axisLine: {
      // 坐标轴线
      lineStyle: {
        // 属性lineStyle控制线条样式
        color: [
          [0.2, "#b23f3a"],
          [0.4, "#e6a23c"],
          [0.6, "#fbd437"],
          [0.8, "#a7ce88"],
          [1, "#5ba934"],
        ],
        width: 20,
      },
    },
    splitLine: {
      lineStyle: {
        color: "#cecece",
      },
    },
    axisLabel: {
      color: "#606266",
    },
    pointer: {
      itemStyle: {
        color: "#1b65b9",
      },
    },
  }
}

export default {
  dictList,
  CHECK_CODE_ZC,
  CHECK_CODE_JYCW,
  CHECK_CODE_WJH,
  CHECK_CODE_YZX,
  CHECK_CODE_CFJZ,
  PAGE_SIZES,
  PAGE_LAYOUT,
  PAGER_COUNT,
  ZHU_CHARTS,
  YIBIAO_CHARTS
}