import axios from 'axios'
import {Message} from 'element-ui'
import { URL_PREV } from '@/utils/global'


// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么

  config.headers['Content-Type'] = 'application/json;charset=UTF-8'
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

function http(url, params, method = 'post') {
  let promise = null
  if (method === 'post') {
    if(typeof params === 'undefined') {
      promise = axios.post(URL_PREV + url);
    } else {
      promise = axios.post(URL_PREV + url, params);
    }
    
  } else if (method === 'get') {
    promise = axios.get(URL_PREV + url, {
      params: params
    })
  }
  return new Promise((resolve, reject)=>{
    promise.then(res=>{
      let { code, data, body, msg} = res.data
      if(code == 0) {
        if(body) {
          Object.assign(data, body)
        }
        resolve(data)
      }else {
        Message.error(msg)
        reject()
      }
    }).catch(err=>{
      console.log(err.message);
      reject()
    })
  })
}

export default {
  http
}
