import Vue from 'vue'
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
      {
        path: '/',
        redirect: '/traceLinkQuery'
      },
      {
        path: '/traceLinkQuery',
        name: '追溯链路查询',
        component: () => import('@/views/trace-link-query')
      },
      {
        path: '/traceCodeQuery',
        name: '追溯码查询',
        component: ()=>import('@/views/trace-code-query')
      },
      {
        path: '/traceCodeNewQuery',
        name: '追溯码查询',
        component: ()=>import('@/views/trace-code-query')
      },
      {
        path: '/codeQuery',
        name: '信息比较',
        component: ()=>import('@/views/code-query')
      }
  ]
})

export default router