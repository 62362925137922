import Vue from 'vue'
import App from './App.vue'
import router from './router'
import API from '@/api'
Vue.prototype.API = API
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import '@/assets/css/reset.styl'
import '@/assets/css/comm.styl'

import Util from '@/utils/util'
Vue.prototype.Util = Util

import GLOBAL from '@/utils/global'
Vue.prototype.GLOBAL = GLOBAL

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
